<template>
  <customModel
    ref="myModal"
    title="预览"
    @handleOk="handdleOk"
    @handleCancel="handleCancel"
    :modalVisible="visible"
    width="1000px"
  >
    <div v-if="stateData.isvideo == 1">
      <!-- <dplayer :video="dplayerObj.video" :highlight="dplayerObj.highlight" /> -->
      <!-- <video-player
        style="width: 100%; max-height: 600px"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player> -->
      <vue3VideoPlay
        v-bind="options"
        poster="https://go.dreamwq.com/videos/ironMan.jpg"
      />
    </div>
    <div v-if="stateData.isvideo == 2">
      <img :src="stateData.picSrc" style="max-width: 100%" alt="" />
    </div>
  </customModel>
</template>
<script >
// import VideoPlayer from "vue-video-player";
// import "video.js/dist/video-js.css";
// import "vue-video-player/src/custom-theme.css";
import videoPlayer from "vue-video-player";
// import "video.js/dist/video-js.min.css";
// import "video.js/dist/lang/zh-CN.js";
// import "videojs-contrib-hls";

import customModel from "@/components/modelComponent/customModel.vue";
import dplayer from "../Dplayer.vue";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  defineExpose,
  watch,
  toRefs,
  reactive,
} from "vue";

export default defineComponent({
  components: {
    customModel,
    dplayer,
    videoPlayer,
  },
  setup(props, { emit }) {
    // 获取实例
    const { proxy } = getCurrentInstance();
    //ref
    const myModal = ref(null);
    const formRef = ref(null);
    //state
    const stateData = reactive({ visible: false, isvideo: 1, picSrc: "" });
    const formState = reactive({
      webname: "",
      webdomin: "",
      web_url: "",
    });
    const options = reactive({
      width: "100%", //播放器高度
      //  height: "450px", //播放器高度
      color: "#409eff", //主题色
      title: "", //视频名称
      src: "", //视频源
      muted: false, //静音
      webFullScreen: false,
      speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
      autoPlay: true, //自动播放
      loop: false, //循环播放
      mirror: false, //镜像画面
      ligthOff: false, //关灯模式
      volume: 0.3, //默认音量大小
      control: true, //是否显示控制器
    });
    const playerOptions = reactive({
      autoplay: true, //如果true,浏览器准备好时开始回放。
      muted: false, // 默认情况下将会消除任何音频。
      loop: false, // 导致视频一结束就重新开始。
      preload: "auto",
      language: "zh-CN",
      aspectRatio: "16:9",
      fluid: true,
      sources: [
        {
          src: "", // 路径
          type: "video/mp4", // 类型
        },
        {
          src: "",
          type: "application/x-mpegURL", // 类型
          withCredentials: false,
        },
        {
          src: "",
          type: "video/webm",
        },
      ],

      poster: "",

      notSupportedMessage: "视频源加载中",
      controlBar: {
        timeDivider: true,
        durationDisplay: true,
        remainingTimeDisplay: false,
        fullscreenToggle: false, //全屏按钮
      },
      techOrder: ["html5"],
      flash: { hls: { withCredentials: false } },
      html5: { hls: { withCredentials: false } },
      controls: true, // 是否显示控制栏
    });
    const visible = ref(false);

    const handleOpenModel = (value, item) => {
      visible.value = value;
      playerOptions.sources[0].src = item.video_url;

      options.src = item.video_url;
      stateData.isvideo = item.material_type;
      stateData.picSrc = item.pic_url;
      console.log(playerOptions);
    };
    const openModelBymethod = (value) => {
      myModal.value.openModal({ visible: value, title: "添加站点" });
    };
    const handleCancel = (value) => {
      visible.value = value;
    };
    const handdleOk = () => {};
    const dplayerObj = reactive({
      video: {
        url: "", //视频地址
        type: "video/mp4",
        // customType: {
        //   customHls: function (video, player) {
        //     const hls = new Hls(); //实例化Hls  用于解析m3u8
        //     hls.loadSource(video.src);
        //     hls.attachMedia(video);
        //   },
        // },
      },
      // danmaku: {
      //   id: "9E2E3368B56CDBB4",
      //   api: "https://api.prprpr.me/dplayer/",
      //   token: "tokendemo",
      //   maximum: 1000,
      //   addition: ["https://api.prprpr.me/dplayer/v3/bilibili?aid=4157142"],
      //   user: "DIYgod",
      //   bottom: "15%",
      //   unlimited: true,
      //   speedRate: 0.5,
      // },
      // contextmenu: [
      //   {
      //     text: "custom1",
      //     link: "https://github.com/DIYgod/DPlayer",
      //   },
      //   {
      //     text: "custom2",
      //     click: (player) => {
      //       console.log(player);
      //     },
      //   },
      // ],
      // highlight: [
      //   {
      //     time: 20,
      //     text: "这是第 20 秒",
      //   },
      //   {
      //     time: 120,
      //     text: "这是 2 分钟",
      //   },
      // ],
    });
    return {
      //   ...toRefs(stateData),
      //state

      visible,
      formState,
      stateData,
      dplayerObj,

      //ref
      formRef,
      myModal,
      //methods
      handdleOk,
      openModelBymethod,
      handleOpenModel,
      handleCancel,
      playerOptions,
      options,
    };
  },
});
</script>
